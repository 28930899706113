import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ErrorComponent } from './error/error.component';
import { HomeComponent } from './home/home.component';
import { LocationsComponent } from './locations/locations.component';

const routes: Routes = [
  { path:'',redirectTo:'home',pathMatch:'full'},
  { path:'home',component:HomeComponent},
  { path:'about',component:AboutComponent},
  { path: 'services', loadChildren: () => import('./services/services.module').then(m => m.ServicesModule)},
  { path:'locations',component:LocationsComponent},
  { path:'contacts',component:ContactsComponent},
  { path: '404',component:ErrorComponent},
  { path: '**',redirectTo:'404',pathMatch:'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
