<section>
    <div class="heading-container">
        <h1>Areas We Serve</h1>
    </div>
</section>
<section class="section">
    <div class="container">
        <div class="section-title">
            <h2>We provide services in these locations</h2>
            <div class="element">
                <img src="/assets/images/title-element.png">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <p class="location-title"> Agoura Hills <span class="line"></span></p>
                <p class="location">Alhambra</p>
                <p class="location">Agoura</p>
                <p class="location">Arcadia</p>
                <p class="location">Arleta</p>
                <p class="location">Artesia</p>
                <p class="location">Azusa</p>
                <p class="location-title"> Beverly Hills <span class="line"></span></p>
                <p class="location">Big Bear</p>
                <p class="location">Bradbury</p>
                <p class="location">Brentwood</p>
                <p class="location">Burbank</p>
                <p class="location-title">Calabasas<span class="line"></span></p>
                <p class="location">Canoga Park</p>
                <p class="location">Carson</p>
                <p class="location">Cathedral City</p>
                <p class="location">Castaic</p>
                <p class="location">Cerritos</p>
                <p class="location">Chastworth</p>
                <p class="location">Commerce</p>
                <p class="location">Covina</p>
                <p class="location">Crestline</p>
                <p class="location">Culver City</p>
                <p class="location-title">Diamond Bar<span class="line"></span></p>
                <p class="location">Desert Hot Springs</p>
                <p class="location">Downey</p>
                <p class="location">Duarte</p>
                <p class="location-title">El Segundo<span class="line"></span></p>
                <p class="location">Encino</p>
                <p class="location-title">Frazier Park<span class="line"></span></p>
            </div>
            <div class="col-3">
                <p class="location-title">Gardena<span class="line"></span></p>
                <p class="location">Glendale</p>
                <p class="location">Glendora</p>
                <p class="location">Granada Hills</p>
                <p class="location-title">Hawaiian Gardens<span class="line"></span></p>
                <p class="location">Hawthorne</p>
                <p class="location">Hermosa Beach</p>
                <p class="location">Hidden Hills</p>
                <p class="location-title">Irwindale<span class="line"></span></p>
                <p class="location-title">La Cañada Flintridge<span class="line"></span></p>
                <p class="location">La Crescenta-Montrose</p>
                <p class="location">La Habra Heights</p>
                <p class="location">La Mirada</p>
                <p class="location">La Puente</p>
                <p class="location">La Verne</p>
                <p class="location">Lake Balboa</p>
                <p class="location">Lake Hughes</p>
                <p class="location">Lake View Terrace</p>
                <p class="location">Lakewood</p>
                <p class="location">Lancaster</p>
                <p class="location">Lawndale</p>
                <p class="location">Long Beach</p>
                <p class="location">Los Angeles</p>
                <p class="location">Lost Hills</p>
                <p class="location-title">Malibu<span class="line"></span></p>
                <p class="location">Manhattan Beach</p>
                <p class="location">Marina Del Rey</p>
                <p class="location">Mission Hills</p>
                <p class="location">Monrovia</p>
                <p class="location">Montebello</p>
            </div>
            <div class="col-3">
                <p class="location">Monterey Park</p>
                <p class="location">Moorpark</p>
                <p class="location-title">Noho Arts District<span class="line"></span></p>
                <p class="location">Hollywood</p>
                <p class="location">North Hills</p>
                <p class="location">North Hollywood</p>
                <p class="location">Northridge</p>
                <p class="location">Norwalk</p>
                <p class="location-title">Oxnard<span class="line"></span></p>
                <p class="location-title">Pacoima<span class="line"></span></p>
                <p class="location">Palmdale</p>
                <p class="location">Palm Desert</p>
                <p class="location">Palm Springs</p>
                <p class="location">Palos Verdes Estates</p>
                <p class="location">Panorama City</p>
                <p class="location">Pasadena</p>
                <p class="location">Pomona</p>
                <p class="location">Porter Ranch</p>
                <p class="location-title">Rancho Palos Verdes<span class="line"></span></p>
                <p class="location">Rancho Cucamonga</p>
                <p class="location">Redondo Beach</p>
                <p class="location">Reseda</p>
                <p class="location">Rosemead</p>
                <p class="location-title">San Dimas<span class="line"></span></p>
                <p class="location">San Fernando</p>
                <p class="location">San Gabriel</p>
                <p class="location">San Marino</p>
                <p class="location">Santa Clarita</p>
                <p class="location">Santa Fe Springs</p>
            </div>
            <div class="col-3">
                <p class="location">Santa Monica</p>
                <p class="location">Shadow Hills</p>
                <p class="location">Sherman Oaks</p>
                <p class="location">Sierra Madre</p>
                <p class="location">Signal Hill</p>
                <p class="location">Simi Valley</p>
                <p class="location">South El Monte</p>
                <p class="location">South Gate</p>
                <p class="location">South Pasadena</p>
                <p class="location">Studio City</p>
                <p class="location">Sun Valley</p>
                <p class="location">Sunland</p>
                <p class="location">Sylmar</p>
                <p class="location-title">Tarzana<span class="line"></span></p>
                <p class="location">Temple City
                </p>
                <p class="location">Toluca Lake
                </p>
                <p class="location">Torrance</p>
                <p class="location">Tujunga</p>
                <p class="location-title">Valley Glen<span class="line"></span></p>
                <p class="location">Valley Village
                </p>
                <p class="location">Van Nuys
                </p>
                <p class="location">Vernon</p>
                <p class="location-title">Walnut<span class="line"></span></p>
                <p class="location">West Covina
                </p>
                <p class="location">West Hills
                </p>
                <p class="location">West Hollywood
                </p>
                <p class="location">Westlake Village
                </p>
                <p class="location">Whittier</p>
                <p class="location">Winnetka</p>
                <p class="location">Woodland Hills
                </p>
            </div>
        </div>
    </div>

</section>
<div>
    <img src="assets/images/banner/map.jpg">
</div>