import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BackService } from 'src/app/back-services/back/back.service';
import { ModalService } from 'src/app/back-services/modal/modal.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  expanded = false;
  loading = false;

  contactForm = this.fb.group({
    email: ['', Validators.required],
    message: ['', Validators.required]
  })
 showSocial = false;
  constructor(private fb: FormBuilder, private backService: BackService, private dialogService: ModalService) { }

  ngOnInit(): void {
  }

  sendMessage() {
    if (this.contactForm.valid) {
      this.loading = true;      
      this.backService.send_email(this.contactForm.value).subscribe(res => {
        console.log(res);
        //@ts-ignore
        if (res.status === 200) {
           //@ts-ignore
          this.dialogService.openDialog('success');
          this.contactForm.reset();
        }else{
          this.dialogService.openDialog('error');
        }
        this.loading = false;
      })
    }

  }

  expCol() {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }

  toggleSocial(){
    if(this.showSocial){
      this.showSocial = false;
    }else{
      this.showSocial = true;
    }

    console.log(this.showSocial);
  }
}
