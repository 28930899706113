<section class="section_1">
    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="5000" color="accent" maxWidth="100%"
        proportion="25" slides="3" [loop]="true" [hideArrows]="true" [hideIndicators]="false" orientation="ltr">
        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide"
            [hideOverlay]="false"></mat-carousel-slide>
    </mat-carousel>
</section>
<!-- !!!! -->
<section class="section section_2 ">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <div class="element">
                <img src="/assets/images/title-element.png">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-12" (mouseenter)="water.show($event)" (mouseleave)="water.hide($event)">
                <div class="service-item water">
                    <div class="icon border-none" routerLink="/services/water">
                        <div class="bg-mask"></div>
                        <img src="assets/images/water-icon.png">
                    </div>
                    <div class="name mt-4">
                        <h3>Water Damage</h3>
                    </div>
                </div>
            </div>
            <p-overlayPanel #water>
                <ng-template pTemplate>
                    <p class="font-weight-bold text-center">Water Damage</p>
                    <span class="line"></span>
                    Water damage may occur because of leaks, sewage backups, and floods. No matter how it starts, the
                    impact can be devastating. To stop further damages by water damage and minimize the impact to your
                    property by calling Ameria Restoration.
                </ng-template>
            </p-overlayPanel>
            <div class="col-sm-4 col-12" (mouseenter)="fire.show($event)" 
                (mouseleave)="fire.hide($event)">
                <div class="service-item fire">
                    <div class="icon" routerLink="/services/fire">
                        <div class="bg-mask"></div>
                        <img src="assets/images/fire-icon.png">
                    </div>

                    <div class="name mt-4">
                        <h3>Fire Damage</h3>
                    </div>
                </div>
            </div>
            <p-overlayPanel #fire>
                <ng-template pTemplate>
                    <p class="font-weight-bold text-center">Fire Damage</p>
                    <span class="line"></span>
                    We take a comprehensive approach to fire damage restoration. We remove soot, smoke odor, and all
                    other remnants of fire. Our team works hard to preserve the memories you’ve made in your home and
                    restore as much of your property as possible.
                </ng-template>
            </p-overlayPanel>
            <div class="col-sm-4 col-12" (mouseenter)="mold.show($event)" 
                (mouseleave)="mold.hide($event)">
                <div class="service-item mold">
                    <div class="icon" routerLink="/services/mold">
                        <div class="bg-mask"></div>
                        <img src="assets/images/mold-icon.png">
                    </div>
                    <div class="name mt-4">
                        <h3>Mold Damage</h3>
                    </div>
                </div>
            </div>
            <p-overlayPanel #mold>
                <ng-template pTemplate>
                    <p class="font-weight-bold text-center">Mold Damage</p>
                    <span class="line"></span>
                    Mold can be extremely hazardous to your health and your property. You should always leave the
                    remediation process to the pros! Your home and everyone in it will be so much safer when we’ve
                    remediated the mold infestation.
                </ng-template>
            </p-overlayPanel>
        </div>
    </div>
</section>

<section class="section section_3 ">
    <img class="w-100" src="assets/images/footer-bg.png">
</section>

<section class="section section_2 ">
    <div class="container">
        <div class="section-title">
            <h2>Professional Restoration Services Are Just One Call Away </h2>
            <div class="element">
                <img src="/assets/images/title-element.png">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-12" (mouseenter)="response.show($event)" (mouseleave)="response.hide($event)">
                <div class="service-item minute">
                    <div class="icon">
                        <div class="bg-mask"></div>
                        <img src="assets/images/photos/fast-time.svg">
                    </div>
                    <div class="name mt-4">
                        <h3>60 Minute Response</h3>
                    </div>
                </div>
            </div>
            <p-overlayPanel #response>
                <ng-template pTemplate>
                    <p class="font-weight-bold text-center">60 Minute Response</p>
                    <span class="line"></span>
                    When life does not go as planned, count on Ameria Restoration! Have fire, water, or wind damaged
                    your home or business? Have an emergency disaster situation? Call Ameria Restoration today, and our
                    expert team will arrive in less than 60 minutes.
                </ng-template>
            </p-overlayPanel>
            <div class="col-sm-4 col-12" (mouseenter)="emergency.show($event)" (mouseleave)="emergency.hide($event)">
                <div class="service-item emergency">
                    <div class="icon">
                        <div class="bg-mask"></div>
                        <img src="assets/images/photos/open-24-hours.svg">
                    </div>
                    <div class="name mt-4">
                        <h3>Emergency
                            Service</h3>
                    </div>
                </div>
            </div>
            <p-overlayPanel #emergency>
                <ng-template pTemplate>
                    <p class="font-weight-bold text-center">Emergency Service</p>
                    <span class="line"></span>
                    If your need is urgent, let Ameria Restoration help! We offer emergency services, so you never have
                    to wait for assistance. We are on standby for your needs 24 hours a day, 7 days a week, 365 days a
                    year.
                </ng-template>
            </p-overlayPanel>
            <div class="col-sm-4 col-12" (mouseenter)="estimate.show($event)" (mouseleave)="estimate.hide($event)">
                <div class="service-item estimate">
                    <div class="icon">
                        <div class="bg-mask"></div>
                        <img src="assets/images/photos/call.svg">
                    </div>
                    <div class="name mt-4">
                        <h3>Estimate Today</h3>
                    </div>
                </div>

            </div>
            <p-overlayPanel #estimate>
                <ng-template pTemplate>
                    <p class="font-weight-bold text-center">Request an Estimate</p>
                    <span class="line"></span>
                    For restoration projects big and small and everything in between, you can trust Ameria Restoration!
                    Need an immediate, detailed onsite inspection? Contact us today for an estimate on your best needs.
                </ng-template>
            </p-overlayPanel>
        </div>
    </div>
</section>
<section class="section section_3 ">
    <div>
        <img class="w-100" src="assets/images/photos/home3.jpg">
    </div>

</section>
<section class="section">
    <div class="container">
        <div class="section-title">
            <h2>Get Your Home Back To Normal Faster </h2>
            <div class="element">
                <img src="/assets/images/title-element.png">
            </div>
        </div>
        <p class="text-content">
            When the unexpected happens or a natural disaster hits your property, let Ameria Restoration be one of the
            first calls you make. Our team of highly trained, certified technicians can provide you with all the
            professional restoration services you need. We help bring a sense of normalcy back to your life. Although it
            can feel like everything has been turned upside down following a fire or flood, we work quickly and
            efficiently to restore what was lost.
        </p>
    </div>
</section>

<section class="section">
    <div class="container">
        <div class="section-title">
            <h2>We Work With All Insurance Companies</h2>
            <div class="element">
                <img src="/assets/images/title-element.png">
            </div>
        </div>
        <p-carousel [value]="insurances" [numVisible]="4" autoplayInterval="2000" [numScroll]="1" [circular]="true"
            dotsContainerClass="d-none" [responsiveOptions]="responsiveOptions">
            <ng-template let-insurance pTemplate="item">
                <div class="insurance-image">
                    <img src="/assets/images/insurance/{{insurance}}" />
                </div>
            </ng-template>
        </p-carousel>
    </div>
</section>