import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BackService } from '../back-services/back/back.service';
import { ModalService } from '../back-services/modal/modal.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  loading = false;

  contactForm = this.fb.group({
    name:['',Validators.required],
    email:['',Validators.required],
    subject:['',Validators.required],
    message:['',Validators.required]
  })
  constructor(private fb:FormBuilder, private backService:BackService, private dialogService: ModalService) { }

  ngOnInit(): void {
  }
  sendMessage() {
    if (this.contactForm.valid) {
      this.loading = true;      
      this.backService.send_email(this.contactForm.value).subscribe(res => {
        //@ts-ignore
        if (res.status === 200) {
           //@ts-ignore
          this.dialogService.openDialog('success');
          this.contactForm.reset();
        }else{
          this.dialogService.openDialog('error');
        }
        this.loading = false;
      })
    }

  }

}
