import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutsModule } from './components/layouts/layouts.module'


import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { LocationsComponent } from './locations/locations.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ErrorComponent } from './error/error.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { MatCarouselModule } from '@ngmodule/material-carousel';
import {CarouselModule} from 'primeng/carousel';
import { GoogleMapsModule } from '@angular/google-maps'
import {OverlayPanelModule} from 'primeng/overlaypanel';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SendMailDialogComponent } from './dialog/send-mail-dialog/send-mail-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    LocationsComponent,
    ContactsComponent,
    ErrorComponent,
    SendMailDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    LayoutsModule,
    BrowserAnimationsModule,
    MatCarouselModule,
    CarouselModule,
    GoogleMapsModule,
    OverlayPanelModule,
    ReactiveFormsModule ,
    HttpClientModule,
    MatDialogModule  
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
