<mat-toolbar>
    <div class="container">
        <div class="navbar">
            <div class="logo">
                <img src="assets/images/logo.png" alt="logo" routerLink="home">
            </div>
            <div class="menu d-lg-flex d-none">
                <a routerLink="home" ><button mat-button routerLinkActive="active-menu">Home</button></a>
                <a routerLink="about" ><button mat-button routerLinkActive="active-menu">About Us</button></a>
                <a  (click)="$event.stopPropagation()" ><button mat-button [matMenuTriggerFor]="services" routerLinkActive="active-menu">Services</button></a>
                <mat-menu #services="matMenu">
                    <button routerLink="services/water" mat-menu-item>Water Damage</button>
                    <button routerLink="services/fire"  mat-menu-item>Fire and Smoke</button>
                    <button routerLink="services/mold" mat-menu-item>Mold Remediation</button>
                </mat-menu>
                <a routerLink="locations"><button mat-button  routerLinkActive="active-menu">Areas We Serve</button></a>
                <a routerLink="contacts" ><button mat-button routerLinkActive="active-menu">Contact Us</button></a>
            </div>
            <div class="mobile-menu d-lg-none d-block">
                <button mat-button [matMenuTriggerFor]="mobileMenu"><i class="fas fa-bars"></i></button>
                <mat-menu #mobileMenu="matMenu">
                    <a routerLink="home" ><button mat-menu-item routerLinkActive="active-menu">Home</button></a>
                    <a routerLink="about" ><button mat-menu-item routerLinkActive="active-menu">About Us</button></a>
                    <a  (click)="$event.stopPropagation()" ><button mat-menu-item [matMenuTriggerFor]="services" routerLinkActive="active-menu">Services</button></a>
                    <mat-menu #services="matMenu" yPosition="above">
                        <button routerLink="services/water" mat-menu-item>Water Damage</button>
                        <button routerLink="services/fire"  mat-menu-item>Fire and Smoke</button>
                        <button routerLink="services/mold" mat-menu-item>Mold Remediation</button>
                    </mat-menu>
                    <a routerLink="locations"><button mat-menu-item routerLinkActive="active-menu">Areas We Serve</button></a>
                    <a routerLink="contacts" ><button mat-menu-item routerLinkActive="active-menu">Contact Us</button></a>
                </mat-menu>
            </div>
            <div class="phone d-sm-flex d-none">
                <div class="content">
                    <i class="fas fa-mobile-alt mr-3"></i>
                    <div class="info">
                        <span class="small">Call Us Now</span>
                        <a href="tel:+18778471117"><h5>(877) 847-1117</h5></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-toolbar>