import { Route } from '@angular/compiler/src/core';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ameria-restoration';
  url:string
  constructor(private router:Router){
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.url = e.url;
      }
    })
  }
}
