import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BackService {

  email_url = 'http://mailer.ameriarestoration.com/mailer.php';

  constructor(private http:HttpClient) { }

  send_email(form){

    return this.http.post(this.email_url,form);

  }
}
