<section>
    <div class="heading-container">
        <h1>About Us</h1>
    </div>
</section>
<section class="section">
    <div class="container">
        <div class="section-title">
            <h2>Ameria Restoration </h2>
            <div class="element">
                <img src="/assets/images/title-element.png">
            </div>
        </div>
        <p class="text-content">
            With over a decade of experience behind us, Ameria Restoration has seen it all! No matter what type of
            restoration services you need, we have you covered. We use our expertise and the most cutting-edge equipment
            to respond to fires, water damage, sewage backups, wind damage, roof and slab leaks, and more!
        </p>
    </div>
</section>

<section class="section">
    <div class="container">
        <div class="section-title">
            <h2>Our Mission</h2>
            <div class="element">
                <img src="/assets/images/title-element.png">
            </div>
        </div>
        <p class="text-content">
            We provide the highest level of restoration services at an affordable price-point for our clients. We ensure
            the longevity of our company through repeat and referral business, achieving customer satisfaction in all
            areas of service. We support a culture of professionalism, integrity, accountability, honesty, attention to
            detail and service mindset. We foster relationships with our suppliers, subcontractors, associates and
            customers. Our driving values are teamwork, excellent service, fairness and mutual respect.
        </p>
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6 capital-letter-image">
                        <img style="height: 377px;" src="assets/images/photos/about1.jpg">
                        <div class="frame-bottom"></div>
                        <div class="frame-right"></div>
                    </div>
                    <div class="col-md-6  pt-5 pt-md-0 capital-letter">
                        <h3>Our Core Values</h3>
                        <p><b>S</b>ervice</p>
                        <p><b>T</b>rust</p>
                        <p><b>R</b>elationship</p>
                        <p><b>I</b>ntegrity</p>
                        <p><b>V</b>alue People</p>
                        <p><b>E</b>xcellence</p>
                    </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6 capital-letter-image frame-left">
                        <img style="height: 377px;" src="assets/images/photos/about2.jpg">
                        <div class="frame-bottom"></div>
                        <div class="frame-right"></div>
                    </div>
                    <div class="col-md-6 pt-5 pt-md-0 capital-letter">
                        <h3>Our Core Focus</h3>
                        <p><b>T</b>ogether</p>
                        <p><b>E</b>veryone</p>
                        <p><b>A</b>chieves</p>
                        <p><b>M</b>ore</p>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>

<section class="section section_3 ">
    <img class="w-100" src="assets/images/photos/about-banner.png">
</section>

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-6">
                        <img src="assets/images/photos/about3.jpg">
                        <img class="pt-4" src="assets/images/photos/about4.jpg">
                    </div>
                    <div class="col-6 pt-5">
                        <img src="assets/images/photos/about5.jpg">
                        <img class="pt-4" src="assets/images/photos/about6.jpg">
                    </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class="section-title">
                    <h2>Restoration Services Customized to Your Needs</h2>
                    <div class="element">
                        <img src="/assets/images/title-element.png">
                    </div>
                </div>
                <p class="text-content">
                    Every property we see is slightly different. We know how to approach a variety of scenarios and
                    customize our methods based on your property’s unique needs. Our technicians have great attention to
                    detail, so you can count on only the highest quality results.<br /><br />

                    We also provide emergency restoration services for your most serious property concerns. When you’re
                    dealing with the aftermath of a natural disaster, you can’t always afford to wait around for
                    restoration services. You need assistance fast to minimize damage, maximize safety, and reduce the
                    cost of cleanup. Ameria Restoration is dedicated to showing up when you need us. We’ll never leave
                    you waiting.
                </p>
                <div class="section-title">
                    <h2>Compassionate Service in Your Time of Need</h2>
                    <div class="element">
                        <img src="/assets/images/title-element.png">
                    </div>
                </div>
                <p class="text-content">
                    Something else that separates us from our competitors is the level of compassion we offer to each of
                    our customers. We know how traumatic dealing with a fire, flood, or other natural disaster can be.
                    That’s why we do everything in our power to minimize your stress. We’re there to support you when
                    you need us the most.
                </p>
            </div>
            <p class="text-content">
                Ameria Restoration builds strong relationships with our customers. By the time we’ve completed work on
                your home, we want you to feel comfortable calling us for all your future restoration needs. We have
                superior communication with all our customers, so you know exactly what is happening with your property
                at every stage of the process.
            </p>
            <p class="text-content">
                Our team will work closely with you, your insurance company, and any other parties involved to ensure
                your property is returned to its pre-loss condition. Our experts have the necessary knowledge, training,
                experience and resources to restore your property quickly and efficiently. Our services are aimed at
                providing you and your family with a safe environment and better air quality.
            </p>
        </div>

    </div>
</section>
<section>
    <div class="container">
        <div class="row pt-md-5">
            <div class="col-md-3 certification-image">
                <img src="assets/images/photos/IICRC.png">
            </div>
            <div class="col-md-9 mt-5 mt-md-0 certification">
                <p class="text-content">
                    Ameria Restoration's specialists are certified by the Institute of Inspection Cleaning and
                    Restoration. We hold WRT, FSRT, AMRT, and OCT certifications. Our technicians are highly specialized
                    and trained in their field of expertise to provide professional restoration services.
                </p>
            </div>
        </div>

    </div>
</section>