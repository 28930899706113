import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { SendMailDialogComponent } from 'src/app/dialog/send-mail-dialog/send-mail-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private dialog:MatDialog) { }

  openDialog(type){
    this.dialog.open(SendMailDialogComponent,{
      width:'400px',
      data:type
    })
  }
}
