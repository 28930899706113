import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  slides: Array<any> = ['/assets/images/banner/banner1.jpg', '/assets/images/banner/banner2.jpg', '/assets/images/banner/banner3.jpg']

  insurances = [
    'aaa-1.png',
     'aig-1.png', 
     'allied-1.png', 
     'allstate-1.png', 
     'chubb-1.png', 
     'cig-1.png', 
     'farmers-1.png', 
     'geico-1.png', 
     'hartford-1.png', 
     'libertymutual-1.png', 
     'mercury-1.png', 
     'nationwide-1.png', 
     'pacificspecialty-1.png', 
     'progressive-1.png', 
     'safeco-1.png', 
     'statefarm-1.png',
     'travelers-1.png',
     'usaa-1.png',
     ]
  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 3,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 3,
      numScroll: 2
    }
  ];

  constructor() { }

  ngOnInit(): void {
    this.sum();
  }

  sum():number{
    let i:number;
    i =8;
    console.log(i);
    return i;
  }

}
