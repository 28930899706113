<div class="footer">
    <div class="footer-content">
        <div class="mask"></div>
        <div class="container">
            <div class="row m-0">
                <div class="col-sm-3 col-12">
                    <div class="logo">
                        <img src="assets/images/logo-white.png" alt="logo" routerLink="home">
                    </div>
                    <div class="contacts mt-3">
                        <p><a href="tel:+18778471117"><i class="fas fa-phone-alt mr-3"></i><span>(877)
                                    847-1117</span></a></p>
                        <p><i class="fas fa-envelope mr-3"></i><span>info@ameriarestoration.com</span></p>
                        <p><i class="fas fa-map-marker-alt mr-3 "></i><span>15500 Erwin St. #1117 </span> <br>
                            <span class="ml-custom"> Van Nuys, CA 91411
                            </span>
                        </p>
                        <div class="social">
                            <div><a href="https://www.facebook.com/ameria.restoration/" target="_blank"><i
                                        class="fab fa-facebook-f"></i></a></div>
                            <div><a href="http://www.yelp.com/biz/ameria-restoration-los-angeles-2" target="_blank"><i
                                        class="fab fa-yelp"></i></a></div>
                            <div><a href="https://www.instagram.com/ameria.restoration/" target="_blank"><i
                                        class="fab fa-instagram"></i></a></div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 col-12">
                    <ul class="menu">
                        <a routerLink="home" routerLinkActive="active-menu">
                            <li>Home</li>
                        </a>
                        <a routerLink="about" routerLinkActive="active-menu">
                            <li>About</li>
                        </a>
                        <li class="cursor-pointer" (click)="expCol()">Services</li>
                        <ul *ngIf="expanded">
                            <a class="d-block" routerLink="services/water" routerLinkActive="active-menu">
                                Water Damage
                            </a><a class="d-block" routerLink="services/fire" routerLinkActive="active-menu">
                                Fire Damage
                            </a><a class="d-block" routerLink="services/mold" routerLinkActive="active-menu">
                                Mold Damage
                            </a>
                        </ul>
                        <a routerLink="locations" routerLinkActive="active-menu">
                            <li>Areas We Serve </li>
                        </a>
                        <a routerLink="contacts" routerLinkActive="active-menu">
                            <li>Contact Us</li>
                        </a>
                    </ul>
                </div>
                <div class="col-sm-6 col-12">
                    <h2 class="contacts-title">Contact Us</h2>
                    <form [formGroup]="contactForm" (ngSubmit)="sendMessage()">
                        <div class="contact-form">
                            <div class="form-group">
                                <input type="email" formControlName="email" class="form-control"
                                    placeholder="Enter email*">
                            </div>
                            <div class="form-group mt-3">
                                <textarea class="form-control" formControlName="message" rows="5"
                                    placeholder="Message"></textarea>
                            </div>
                            <button mat-raised-button [disabled]="loading">
                                <span *ngIf="!loading">Send</span>
                                <i *ngIf="loading" class="fas fa-sync fa-spin"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
    <div class="footer-bottom">
        <button  mat-raised-button class="mobilePhone d-sm-none" (click)="toggleSocial()"><i class="fas fa-ellipsis-h"></i></button>
        <div class="social-menu" [ngClass]="{'d-block': showSocial, 'd-none': !showSocial, 'third': false}">
            <a href="tel:+18778471117" class=" icons">
                <i class="fas fa-phone-alt"></i>
            </a>
            <a href="mailto:info@ameriarestoration.com" class=" icons">
                <i class="far fa-envelope"></i>
            </a>
        </div>
        

        <!-- <div class="container"> -->
        <div class="row text-left">
            <div class="col-5 pl-5">
                <a href="https://demodynamics.net/" target="_blank">powered by Demodynamics</a>
            </div>
            <div class="col-7">
                Copyright © 2020 Ameria Restoration. All rights reserved.
            </div>

        </div>
        <!-- </div> -->
    </div>
</div>