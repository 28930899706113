<section>
    <div class="heading-container">
        <h1>Contact Us</h1>
    </div>

</section>
<section class="contacts-section">
    <div class="container">
        <p class="text-content mt-5">
            Do you need property restoration services in California? Reach out to Ameria Restoration today! We will get
            back to you promptly or come to your
            property right away if it's an emergency. With our services, you can feel safe on your property again!
        </p>
        <div class="row pt-5">
            <div class="col-md-6">
                <div class="section-title">
                    <h2>Send Your Message</h2>
                    <div class="element">
                        <img src="/assets/images/title-element.png">
                    </div>
                </div>
                <form [formGroup] = "contactForm" (ngSubmit)="sendMessage()">
                <div>
                    <div class="form-group">
                        <input formControlName="name" class="form-control" placeholder="Name*">
                    </div>
                    <div class="form-group">
                        <input formControlName="email" class="form-control" placeholder="Email*">
                    </div>
                    <div class="form-group">
                        <input formControlName="subject" class="form-control" placeholder="Subject*">
                    </div>
                    <div class="form-group">
                        <textarea formControlName="message" class="form-control" role="30" placeholder="Your Message*">

                        </textarea>
                    </div>
                    <div class="form-group float-right">
                        <button class="btn send-btn" [disabled]="loading">
                            <span *ngIf="!loading">Send</span>
                            <i *ngIf="loading" style="padding: 0 10px" class="fas fa-sync fa-spin"></i>
                        </button>
                    </div>
                </div>
            </form>
            
            </div>
            <div class="col-md-6 contact-credentails">
                <div class="section-title">
                    <h2>Contact Us</h2>
                    <div class="element">
                        <img src="/assets/images/title-element.png">
                    </div>
                </div>
                <div class="credentails">
                    <div>
                       <a href="tel:+18778471117"><i class=" color-icon fas fa-phone-alt"></i> <span>(877) 847-1117</span></a>
                    </div>
                    <div>
                        <i class="color-icon fas fa-envelope"></i> <span>info@ameriarestoration.com</span>
                    </div>
                    <div>
                        <i class="color-icon fas fa-map-marker-alt"></i> <span>15500 Erwin St. #1117 </span> <br>
                        <span style="margin-left: 31px;"> Van Nuys, CA 91411
                         </span>
                    </div>
                </div>
                <div class="section-title">
                    <h2>We Are Social</h2>
                    <div class="element">
                        <img src="/assets/images/title-element.png">
                    </div>
                </div>
                <div class="social">
                    <div>
                        <a href="https://www.facebook.com/ameria.restoration/" target="_blank"><i class="fab fa-facebook-square"></i></a> 
                        <a href="http://www.yelp.com/biz/ameria-restoration-los-angeles-2" target="_blank"><i class="fab fa-yelp"></i></a>
                        <a href="https://www.instagram.com/ameria.restoration/" target="_blank"><i class="fab fa-instagram"></i></a>
                    </div>
                    
                </div>

            </div>

        </div>
    </div>
</section>